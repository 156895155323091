import { Component, OnInit } from '@angular/core';
import { LawyerinfoService } from '../../lawyerinfo.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { JwtService } from '../../jwt.service';
import { AppComponent } from '../../app.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { AlertService } from '../../alert-message/alert.service';
import { HttpClient } from '@angular/common/http';
//import { LeftsidebarComponent } from '../../leftsidebar/leftsidebar.component';
import { isNull } from '@angular/compiler/src/output/output_ast';
import { AlertComponent } from 'src/app/alert/alert.component';
//import { first, all } from "macaddress-local-machine";
//import macAddr from "macaddress-local-machine";
//import { getNetworkIFaceOne, getMac, isVirtualMac } from '@lzwme/get-physical-address';
//import { networkInterfaces } from 'os'
//import { getMAC, parseMAC } from '@ctrl/mac-address';
const endpoint = "https://admin.bdlawservice.com/public/api/";

@Component({
  selector: 'app-homecontent',
  templateUrl: './logincontent.component.html',
  styleUrls: ['./logincontent.component.css']
})
export class LogincontentComponent implements OnInit {

  scrollnews: any = [];
  scrollnewsdetails: any = [];
  errorMessage: string;
  errorMessagelcms: string;
  errorMessagebdjc: string;
  isLoadingImage: boolean = false;
  userdata:any = [];
  lcmsuserdata:any = [];
  lawyerinfodata:any = [];
  username: any;
  password: any;
  lawyerCode: any;
  mobile: any;
  
  ipAddress: any;
  Judgecourtpassword: any;
  judgeCourtcode: any;
  nw: any;
  isLoading: boolean = false;
  ismodal:boolean = false;
  ismodallcms:boolean = false;
 ismodalcomming:boolean = false;
  isLoadinglcms: boolean = false;
  is_loggedin: boolean = false;
  is_lcms_loggedin: boolean = false;
  is_loggedout: boolean = true;
  isLogin: boolean = false;
  isLogout: boolean = true;
  current_laywerCode: string;
  rememberMe: boolean = false;
  lcmsrememberMe: boolean = false;
  toketString: string;
  blrUrl:any;
  bdlawUrl:string;
  totalnotification:any = [];



  constructor(
    public lawyerinfo_api: LawyerinfoService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    public rest:JwtService,  
    public app:AppComponent,
    //public leftber:LeftsidebarComponent,
    private activatedRoute: ActivatedRoute,
    private SimpleModalService: SimpleModalService,
    public sport: AlertService

   
   
  ) { 
    this.http.get<{ ip: string }>('https://api.ipify.org/?format=json')
      .subscribe(data => {
        //console.log('the data', data);
        this.ipAddress = data.ip;

       
      })
  }
 // const webdriver = require('selenium-webdriver');

  // Input capabilities
  openNewTab (){
    var newTabWindow = open();
    return newTabWindow;
}

updateTabLocation(tabLocation, tab) {
    if(!tabLocation){
      tab.close();
    }
    tab.location.href = tabLocation;
}


  ngAfterViewInit(){
  //  this.leftber.gettodayonlinevisitor();

  //  this.leftber.gettodaytotalvisitor();
   this.app.getDeviceInformation();
    //this.app.gescrollnews();
   // this.gebodynews();
    // this.gebodynewsdetails();
    // this.current_laywerCode = this.app.getLawyerCode();    
    
    // if(this.current_laywerCode != null && this.current_laywerCode != "sadmin"){
    //   this.getLawyerInfo(this.current_laywerCode);
    //   this.gettotalnotification();
    // }

    let rememberMe = localStorage.getItem('rememberMe');
    if(rememberMe=='yes'){
     
      this.rememberMe=true;
      this.username = localStorage.getItem('rememberMeuser');
      this.password = localStorage.getItem('rememberMepass');
    }else{
      this.rememberMe=false;
     
    }

    let lcmsrememberMe = localStorage.getItem('lcmsrememberMe');
    if(lcmsrememberMe=='yes'){
     
      this.lcmsrememberMe=true;
      this.lawyerCode = localStorage.getItem('lcmsrememberMeuser');
      this.mobile = localStorage.getItem('lcmsrememberMepass');
    }else{
      this.lcmsrememberMe=false;
     
    }

  }
  
  ngOnInit() {
   //alert(this.ipAddress );
  //  const macAddress = networkInterfaces();
  //  this.ipAddress = macAddress;
  //  var nw: any;
  //  var os = nw.require('os');
  //  document.write(JSON.stringify(os.networkInterfaces(), null, 2));
//     const getmac = require('getmac')
 //alert(JSON.stringify(os.networkInterfaces(), null, 2));
// const callMac = () =>{
//     return getmac.default()
// }
// callMac();
   // console.log(getMAC())
  // getMac().then(mac => console.log(`the MAC address is ${mac}`));
   //const mac = getMAC();
   this.lawyerinfo_api
  //  .geIpadrress()
  //  .subscribe(
  //    ip => {
  //      this.ipAddress = ip.ip
  //    },
  //    error => {
  //      this.errorMessage = <any>error
  //    }
  //  );
  

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
       
        if(this.app.loggedIn()){         
          this.is_loggedin = true;         
        } else {
          this.is_loggedin = false;
        }
      }
    });

    if(this.app.loggedIn()){         
      this.is_loggedin = true;         
    } else {
      this.is_loggedin = false;
    }
  }

  getUserIP(onNewIP) { //  onNewIp - your listener function for new IPs
    //compatibility for firefox and chrome
    // console.log("onNewIP" , onNewIP);
    // (<any>window).mozRTCPeerConnection 
    var myPeerConnection = (<any>window).RTCPeerConnection || (<any>window).mozRTCPeerConnection  || (<any>window).webkitRTCPeerConnection;
    var pc = new myPeerConnection({
        iceServers: []
    }),
    noop = function() {},
    localIPs = {},
    ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g,
    key;

    function iterateIP(ip) {
        if (!localIPs[ip]) onNewIP(ip);
        localIPs[ip] = true;
    }

 //create a bogus data channel
 pc.createDataChannel("");
    // create offer and set local description
    pc.createOffer(function(sdp) {
        sdp.sdp.split('\n').forEach(function(line) {
            if (line.indexOf('candidate') < 0) return;
            line.match(ipRegex).forEach(iterateIP);
        });

        pc.setLocalDescription(sdp, noop, noop);
    }, noop); 

    //listen for candidate events
    pc.onicecandidate = function(ice) {
        if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
        ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
    };
}

nextModal(){
  // alert("kkdjknkln")
  // var url = "http://localhost:4200/";
   //window.open(url, "_blank");
   let element:HTMLElement = document.getElementById('auto_triggers1') as HTMLElement; 
   element.click();
 
   this.ismodal = false;
 
 }

//newcode
actionLogin() {
 // this.showAlerts();
 this.getUserIP;

  let postData = {
    username: this.username,
    password: this.password,
    apps_user_type:1,
    is_mobile:2
  }
  
  //console.log(postData);
  let  dd = btoa(postData.username+"&pass="+postData.password);
  this.isLoading = true;
  

  this.rest
      .bdLawlogin(postData)
      .subscribe(
        userdata => {
          // console.log(userdata);
          //   this.userdata = userdata;
          //   this.lawyerinfodata = '';
          //   this.isLogin = false;
          //   this.isLogout = true;
          //   this.is_loggedin = true;
          //   this.is_loggedout = false;
            this.isLoading = false;
          //   this.errorMessage = '';  
            
          //   if(this.rememberMe){
          //     localStorage.setItem('rememberMe', 'yes');
          //     localStorage.setItem('rememberMeuser',  this.username);
          //     localStorage.setItem('rememberMepass',  this.password);
             
          //   }   
          //   else{
          //     localStorage.removeItem("rememberMe");
          //     localStorage.removeItem("rememberMeuser");
          //     localStorage.removeItem("rememberMepass");
          //     this.username = '';
          //     this.password = '';
          //   }   

           // var url = "https://new.siddiqueenterprise.com/home";
            var url = "https://new.bdlawservice.com/home?token="+dd;
            this.bdlawUrl=url;
            //var url = "http://localhost:4200/home";

          //  window.open(url, "_blank");
       
                var win= window.open(url);
          
                 if (win) {
                   //Browser has allowed it to be opened
                   win.focus();
               } else {
                  
                   this.ismodal = true;
                 
                   setTimeout(()=>this.nextModal(), 1);
                  setTimeout(()=> location.reload(), 10);
              
               }
          
         // location.reload();
        // setTimeout(()=> location.reload(), 1000);
            
          },
          error => {
              this.errorMessage = <any>error
              this.isLogin = true
              this.isLogout = false
              this.is_loggedin = false;
              this.is_loggedout = true;
              this.isLoading = false;
              this.username = '';
              this.password = '';
          }
      );

  //      }
    
}



nextModallcms(){
  let element:HTMLElement = document.getElementById('auto_triggerslcms') as HTMLElement; 
  element.click();
  this.ismodallcms = false;

}
nextModalcomming(){
  let element:HTMLElement = document.getElementById('auto_triggerslcms2') as HTMLElement; 
  element.click();
  this.ismodalcomming = false;

}

showAlerts() {
  

  this.SimpleModalService.addModal(AlertComponent, { title: 'Please disale firefox popup prevantion', message: 'For avoid this massage Please click Option button and Select Allows Pop-ups for new.bdlawservice.com Or click Nextpage Button ' });
}
closeModal(){
  this.ismodal = false;
  this.ismodallcms = false;
  this.ismodalcomming = false;
}


actionLogcomming(){

  let postData = {
    username: this.judgeCourtcode,
    password: this.Judgecourtpassword,
    apps_user_type:2,
    is_mobile:2
  }

  //alert(postData.username);
  //console.log(postData.username)
  //var url = "https://bdjudgecourt.com/";
  //var url = "http://localhost:19006/?userId="+postData.username+"&token="+postData.password+"";
  this.lawyerinfo_api
      .blrlogin(postData)
      .subscribe(
        lcmsuserdata => {
          console.log(lcmsuserdata);
            this.lcmsuserdata = lcmsuserdata;

        //alert(Number(this.mobile));
            this.isLoadinglcms = false;
         //if(this.lawyerCode===lcmsuserdata.lawyer_id && Number(this.mobile)===Number(lcmsuserdata.mobile)){
        
         if(lcmsuserdata.code===200){

  let  dd = btoa(postData.username+"&pass="+postData.password);
  
  var url = "https://bdjudgecourt.com/?token="+dd;
  this.toketString=url;
  localStorage.setItem("comming_soon", "comming"); 
  //var win= window.open(url, "about:blank");
  var win= window.open(url);
              
                 if (win) {
                   //Browser has allowed it to be opened
                  
                   win.focus();
               } else {
                  
                   this.ismodalcomming = true;
                 
                   setTimeout(()=>this.nextModalcomming(), 1);
                  setTimeout(()=> location.reload(), 10);
              
               }
              }
              else{
                this.errorMessagebdjc="User Code No. Or Password not Match";
                this.judgeCourtcode ='';
                this.Judgecourtpassword='';
               }
             },
            error => {
                this.errorMessagebdjc = <any>error
              //  window.location.href='https://lcmsbd.com/';
                this.isLoadinglcms = false;
                this.lawyerCode ='';
                this.mobile='';
            }
        );
              

}
actionlcmsLogin() {
  let postData = {
    username: this.lawyerCode,   
    password: this.mobile,
    apps_user_type:3,
    is_mobile:2,
    ip:this.ipAddress
   
  }
 

  
  
  
  console.log(this.lawyerCode, this.ipAddress);
  this.isLoadinglcms = true;
  //window.location.href='https://lcmsbd.com/'; 
   this.lawyerinfo_api
       .blrlogin(postData)
       .subscribe(
         lcmsuserdata => {

              console.log(lcmsuserdata);
                this.lcmsuserdata = lcmsuserdata;
                this.isLoadinglcms = false;
             
            if(lcmsuserdata.code===200)
            {

              this.is_lcms_loggedin = true;
              let  dd = btoa(postData.username+"&pass="+postData.password);
              let final_url = 'https://bdlawreference.com/Common/dokarindexlawbddibinak'
            //   let urls = this.router.serializeUrl(this.router.createUrlTree([final_url+'/0'], {
            //     queryParams: {
            //       token: dd,
                
            //     }
            // }))
//alert(urls);
              //let urls =   this.router.navigate([final_url], { queryParams: {token:dd}});
             var urls = "https://bdlawreference.com/Common/dokarindexlawbddibinak/?token="+dd;
              //var urls = "http://localhost:30227/Common/dokarindexlawbddibinak/?token="+dd;
              this.blrUrl=urls;
              // let url='https://new.bdlawreference.com/Common/dokarindexlawbddibinak';
              var win= window.open(urls);
            // var win = this.router.navigate([final_url], { queryParams: {token:dd}});
                if (win) {
                    //Browser has allowed it to be opened
                    win.focus();
                } else {
                    
                    this.ismodallcms = true;
                    setTimeout(()=>this.nextModallcms(), 1);
                    setTimeout(()=> location.reload(), 10);
                }
    
              //window.location.href='https://new.bdlawreference.com/Common/dokarindexlawbddibinak'  
              // window.open("https://new.bdlawreference.com/Common/dokarindexlawbddibinak", "_blank");
                this.errorMessagelcms='';
            
                if(this.lcmsrememberMe){
                  localStorage.setItem('lcmsrememberMe', 'yes');
                  localStorage.setItem('lcmsrememberMeuser',   this.lawyerCode);
                  localStorage.setItem('lcmsrememberMepass',   this.mobile);
                  
                }   
                else{
                  localStorage.removeItem("lcmsrememberMe");
                  localStorage.removeItem("lcmsrememberMeuser");
                  localStorage.removeItem("lcmsrememberMepass");
                  this.lawyerCode ='';
                  this.mobile='';
                }  
              }
              else{
               if( lcmsuserdata.message){
                this.errorMessagelcms=lcmsuserdata.message;
               }else{
                  this.errorMessagelcms="User Code No Or Password No not Match";
               }
               
                this.lawyerCode ='';
                this.mobile='';
              }
           },
           error => {
               this.errorMessagelcms = <any>error
             //  window.location.href='https://lcmsbd.com/';
               this.isLoadinglcms = false;
               this.lawyerCode ='';
               this.mobile='';
           }
       );

  
    
}




actionLogout() {

  this.isLoading = true;

  this.rest.logout();

  this.isLogin = true
  this.isLogout = false
  this.is_loggedin = false;
  this.is_loggedout = true;
  this.isLoading = false;
  this.router.navigateByUrl('/');
 // location.reload();

}

getLawyerInfo(lawyerCode) {

  let postData = {
    lawyerCode: lawyerCode
  }
  
  this.lawyerinfo_api
          .getLawyerinfo(postData)
          .subscribe(
              lawyerinfodata => {
                  this.lawyerinfodata = lawyerinfodata
              }
          );
}


gettotalnotification() {
  let postData = {
    current_laywerCode:  this.current_laywerCode,
  }
  //console.log(this.current_laywerCode);

  this.lawyerinfo_api
      .gettotalnotification(postData)
      .subscribe(
        totalnotification => {
            this.totalnotification = totalnotification
          },
          error => {
              this.errorMessage = <any>error
          }
      );
}

/////////////
  gebodynews() {

    let postData = {
    }
    //console.log(postData);

    this.lawyerinfo_api
        .gebodynews(postData)
        .subscribe(
          scrollnews => {
           // console.log(courtwisedata);
              this.scrollnews = scrollnews
            },
            error => {
                this.errorMessage = <any>error
            }
        );
  }

  gebodynewsdetails() {
    this.isLoadingImage = true;
    let postData = {
    }
    //console.log(postData);

    this.lawyerinfo_api
        .gebodynewsdetails(postData)
        .subscribe(
          scrollnewsdetails => {
           // console.log(courtwisedata);
              this.scrollnewsdetails = scrollnewsdetails
              this.isLoadingImage = false
            },
            error => {
                this.errorMessage = <any>error
                this.isLoadingImage = false
            }
        );
  }

}
