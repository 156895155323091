import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  ipAddress:any;
  getCurrentYear: any;
  constructor(
    private http: HttpClient
  ) { this.http.get<{ ip: string }>('https://api.ipify.org/?format=json')
  .subscribe(data => {
    //console.log('the data', data);
    this.ipAddress = data.ip;

   
  }) }

  ngOnInit() {
    this.getCurrentYear = new Date().getFullYear();
  }

}
